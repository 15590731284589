@switch (layout()) {
  @case (AppLayout.Authorized) {
    @if (hasCompletedOnboarding()) {
      @defer {
        <s-authorized-layout>
          <router-outlet />
        </s-authorized-layout>
      }
      @if (loading()) {
        <s-loading
          class="fixed left-0 top-0 z-[9999999]"
          @fadeOut
          size="48"
        ></s-loading>
      }
    }
  }
  @case (AppLayout.Blank) {
    @defer {
      <s-blank-layout>
        <router-outlet />
      </s-blank-layout>
    }
  }
  @case (AppLayout.Error) {
    @defer {
      <s-error-layout>
        <router-outlet />
      </s-error-layout>
    }
  }
  @default {
    @defer {
      <s-unauthorized-layout>
        <router-outlet />
      </s-unauthorized-layout>
    }
  }
}

<span
  class="pointer-events-none fixed bottom-0 right-0 z-50 flex w-11 transform justify-center font-nunito-sans text-sm font-bold text-black/50"
>
  {{ version() }}
</span>
