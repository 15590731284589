<!-- <ng-container [formGroup]="form">
  <s-rich-text-editor
    [formControl]="descriptionFormControl"
    [editing]="true"
    [closeable]="false"
  ></s-rich-text-editor>
</ng-container>

<hr />

<pre [innerHTML]="descriptionFormControl.value"></pre>

<s-rich-text-editor
  [editable]="true"
  [editing]="false"
  [content]="descriptionFormControl.value!"
  [sanitize]="false"
></s-rich-text-editor> -->

<emoji-mart
  [darkMode]="true"
  class="emoji-mart"
  (emojiSelect)="hndAddEmoji($event)"
  title="Pick your emoji…"
></emoji-mart>

<button sButton (click)="hndLoginWithTrello()">Login with Trello</button>
<nz-select>
  @for (board of trelloBoards(); track $index) {
    <nz-option [nzValue]="board.id" [nzLabel]="board.name"></nz-option>
  }
</nz-select>
<button (click)="throwTestError()">Test Sentry Error</button>
